// src/pages/ProductDetail.jsx
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CartContext } from '../context/cartcontext';
import ProductCard from '../components/productcard';

function ProductDetail() {
  const { id } = useParams();
  const { addToCart } = useContext(CartContext);
  const [product, setProduct] = useState({
    id: 1,
    name: '(Debadged) placeholder | placeholder',
    price: 12.00,
    image: '/images/image.png',
    thumbnails: ['/images/image.png', '/images/image2.png', '/images/image3.png'],
    spawnCode: 'placeholder',
  });
  const [mainImage, setMainImage] = useState(product.image);
  const [quantity, setQuantity] = useState(1);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    // Fetch product details based on ID if available
    // For demonstration, we're using static data
    // Replace this with actual data fetching logic
    // Example:
    // fetch(`/api/products/${id}`)
    //   .then(response => response.json())
    //   .then(data => setProduct(data));
  }, [id]);

  const handleThumbnailClick = (img) => {
    if (mainImage !== img) {
      setIsFading(true);
      setTimeout(() => {
        setMainImage(img);
        setIsFading(false);
      }, 500); // Match animation duration
    }
  };

  const handleAddToCart = () => {
    if (quantity < 1) {
      alert('Please enter a valid quantity.');
      return;
    }

    addToCart(product, quantity);
    alert(`Added ${quantity} item(s) to cart. Spawn Code: ${product.spawnCode}`);
  };

  return (
    <section className="container mx-auto p-6 h-screen overflow-hidden" data-aos="fade-up">
      <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 overflow-auto">
        {/* Product Image and Gallery */}
        <div className="md:w-1/2">
          <img
            src={mainImage || '/images/image.png'}
            alt="Product"
            className={`rounded-lg w-full h-auto mb-4 shadow-lg product-image transition-opacity duration-500 ${isFading ? 'opacity-0' : 'opacity-100'}`}
          />
          <div className="flex space-x-4 overflow-x-auto">
            {product.thumbnails.map((thumb, index) => (
              <img
                key={index}
                src={thumb}
                alt={`Thumbnail ${index + 1}`}
                className="w-24 h-24 object-cover rounded-md cursor-pointer"
                onClick={() => handleThumbnailClick(thumb)}
              />
            ))}
          </div>
        </div>

        {/* Product Details */}
        <div className="md:w-1/2">
          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
          <p className="text-gray-400 text-xl mb-6">${product.price.toFixed(2)}</p>

          {/* File Info Table */}
          <table className="table-auto w-full text-left mb-6">
            <thead>
              <tr>
                <th className="px-4 py-2">File Name</th>
                <th className="px-4 py-2">Type</th>
                <th className="px-4 py-2">Size</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-800">
                <td className="border px-4 py-2">{product.name}</td>
                <td className="border px-4 py-2">ZIP</td>
                <td className="border px-4 py-2">27.1 MB</td>
              </tr>
            </tbody>
          </table>

          {/* Quantity Selector */}
          <div className="mb-4">
            <label htmlFor="quantity" className="block text-sm font-medium text-gray-200">Quantity</label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={quantity}
              min="1"
              onChange={(e) => setQuantity(parseInt(e.target.value))}
              className="mt-1 block w-16 px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
            />
          </div>

          {/* Add to Cart Button */}
          <button
            onClick={handleAddToCart}
            className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300"
          >
            Add to Cart
          </button>

          {/* Spawn Code */}
          <p className="mt-4 text-gray-400">Spawn Code: {product.spawnCode}</p>
        </div>
      </div>

      {/* Related Products */}
      <div className="mt-12" data-aos="fade-up">
        <h2 className="text-2xl font-bold mb-6">You May Also Like</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Sample Related Products */}
          <ProductCard product={{ id: 4, name: '(Debadged) placeholder', price: 8.00, image: '/images/image.png' }} />
          <ProductCard product={{ id: 5, name: '(Debadged) placeholder', price: 8.00, image: '/images/image2.png' }} />
          <ProductCard product={{ id: 5, name: '(Debadged) placeholder', price: 8.00, image: '/images/image2.png' }} />
          <ProductCard product={{ id: 5, name: '(Debadged) placeholder', price: 8.00, image: '/images/image2.png' }} />
          
          {/* Add more related products as needed */}
        </div>
      </div>
    </section>
  );
}

export default ProductDetail;