// src/pages/Cart.jsx
import React, { useState, useEffect } from 'react';

function Cart() {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []);

  const handleQuantityChange = (id, quantity) => {
    const updatedCart = cart.map(item => item.id === id ? { ...item, quantity } : item);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleRemove = (id) => {
    const updatedCart = cart.filter(item => item.id !== id);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  if (cart.length === 0) {
    return <div className="p-6 text-[#EEEEEE]">Your cart is empty.</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-[#EEEEEE]">Your Cart</h1>
      <table className="min-w-full bg-[#393E46] text-[#EEEEEE]">
        <thead>
          <tr>
            <th className="py-2">Product</th>
            <th className="py-2">Price</th>
            <th className="py-2">Quantity</th>
            <th className="py-2">Total</th>
            <th className="py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {cart.map(item => (
            <tr key={item.id} className="text-center">
              <td className="py-2">{item.name}</td>
              <td className="py-2">${item.price.toFixed(2)}</td>
              <td className="py-2">
                <input
                  type="number"
                  value={item.quantity}
                  min="1"
                  onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                  className="w-16 px-2 py-1 bg-[#222831] text-[#EEEEEE] rounded"
                />
              </td>
              <td className="py-2">${(item.price * item.quantity).toFixed(2)}</td>
              <td className="py-2">
                <button
                  onClick={() => handleRemove(item.id)}
                  className="bg-[#00ADB5] hover:bg-[#00ADB5] text-[#222831] px-3 py-1 rounded"
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-6 text-right text-[#EEEEEE]">
        <span className="text-xl font-bold">Total: ${totalPrice.toFixed(2)}</span>
      </div>
    </div>
  );
}

export default Cart;