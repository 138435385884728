// src/pages/Home.jsx
import React from 'react';
import ProductCard from '../components/productcard';

function Home() {
  const products = [
    // Sample products. Replace with actual data or fetch from an API
    { id: 1, name: 'Placeholder', price: 10.00, image: '/images/image.png' },
    { id: 2, name: 'Placeholder', price: 10.00, image: '/images/image.png' },
    { id: 3, name: 'Placeholder', price: 10.00, image: '/images/image.png' },
    { id: 4, name: 'Placeholder', price: 10.00, image: '/images/image.png' },
    { id: 5, name: 'Placeholder', price: 10.00, image: '/images/image.png' },
    { id: 6, name: 'Placeholder', price: 10.00, image: '/images/image.png' },
    { id: 7, name: 'Placeholder', price: 10.00, image: '/images/image.png' },
    // Add more products as needed
  ];

  return (
    <div className="flex flex-col md:flex-row p-4 space-y-8 md:space-y-0 md:space-x-8 h-screen overflow-hidden">
      {/* Sidebar */}
      <aside className="md:w-1/4 bg-[#393E46] p-6 rounded-lg shadow-lg h-full md:h-auto md:overflow-auto" data-aos="fade-right">
        <h3 className="text-lg font-bold text-[#EEEEEE] mb-6">Categories</h3>
        <ul className="space-y-4">
          <li><a href="#" className="text-[#ffffff] hover:text-[#EEEEEE] transition duration-300">All Brands</a></li>
          <li><a href="#" className="text-[#ffffff] hover:text-[#EEEEEE] transition duration-300">MLOs</a></li>
          <li><a href="#" className="text-[#ffffff] hover:text-[#EEEEEE] transition duration-300">Badged Cars</a></li>
          <li><a href="#" className="text-[#ffffff] hover:text-[#EEEEEE] transition duration-300">Debadged Cars</a></li>
          <li><a href="#" className="text-[#ffffff] hover:text-[#EEEEEE] transition duration-300">Lore Friendly</a></li>
        </ul>
      </aside>

      {/* Main Content */}
      <main className="md:w-3/4 p-6 overflow-auto" data-aos="fade-left">
        <h2 className="text-3xl font-bold text-[#EEEEEE] mb-6">Debadged Cars</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
          {/* Add more ProductCard components as needed */}
        </div>
      </main>
    </div>
  );
}

export default Home;