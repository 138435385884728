// src/components/Header.jsx
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../context/cartcontext';

function Header() {
  const { cart } = useContext(CartContext);
  const cartCount = cart.reduce((total, item) => total + item.quantity, 0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Navigation links array
  const navLinks = [
    { to: "/", label: "Home" },
    { to: "/shop", label: "Shop" },
    { to: "/debadged", label: "Debadged" },
    { to: "/tune-options", label: "Tune Options" },
    { to: "/faq", label: "FAQ" }
  ];

  return (
    <header className="bg-[#222831] p-6 flex justify-between items-center shadow-lg" data-aos="fade-down">
      <div className="logo">
        <Link to="/" className="text-4xl font-extrabold text-[#EEEEEE] tracking-wide">N93x</Link>
      </div>
      <nav className="hidden md:flex space-x-6">
        {navLinks.map((link, index) => (
          <Link key={index} to={link.to} className="text-[#ffffff] hover:text-[#EEEEEE] transition duration-300">
            {link.label}
          </Link>
        ))}
      </nav>
      <div className="cart relative">
        <Link to="/cart" className="relative">
          <svg className="w-6 h-6 text-[#EEEEEE]" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13l-1.5 7h13L17 13m-6 0v7"></path>
          </svg>
          <span id="cart-count"
            className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-[#222831] bg-[#ffffff] rounded-full">
            {cartCount}
          </span>
        </Link>
      </div>
      <button className="md:hidden text-[#EEEEEE]" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
      {isMenuOpen && (
        <div className="absolute top-16 left-0 w-full bg-[#222831] p-6 md:hidden">
          <nav className="flex flex-col space-y-4">
            {navLinks.map((link, index) => (
              <Link key={index} to={link.to} className="text-[#ffffff] hover:text-[#EEEEEE] transition duration-300">
                {link.label}
              </Link>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;