// src/components/Footer.jsx
import React from 'react';

function Footer() {
  return (
    <footer className="bg-[#222831] text-[#EEEEEE] p-4 text-center">
      © {new Date().getFullYear()} N93x. All rights reserved.
    </footer>
  );
}

export default Footer;