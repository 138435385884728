// src/components/ProductCard.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

function ProductCard({ product }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/product/${product.id}`);
  };

  return (
    <div
      className="bg-[#393E46] p-6 rounded-lg shadow-lg product-card transform transition duration-300 cursor-pointer"
      onClick={handleClick}
    >
      <img src={product.image} alt={product.name} className="rounded-md w-full h-auto" />
      <p className="text-[#EEEEEE] mt-4 font-semibold">{product.name}</p>
      <span className="block text-[#EEEEEE] mt-2 text-lg">${product.price.toFixed(2)}</span>
    </div>
  );
}

export default ProductCard;